/**
 *
 * IndexPage
 *
 *
 */

import React from 'react';
import styled from 'styled-components';

import { Link } from 'gatsby';

const IndexPage = () => {



  return (
    <Container>
      <CtaContainer>
        <h1>Fore!</h1>
        <h2>Looks like this page is lost in the fescue.</h2>
        <StyledLink to="/">Re-Tee</StyledLink>
      </CtaContainer>
      <img className={'golfers'} src={'/images/golfers.png'} alt="Graphic depicting four golfers"/>
      <img className={'golfers-desktop'} src={'/images/desktop-golfers.png'} alt="Graphic depicting four golfers"/>
    </Container>
  );
};

IndexPage.propTypes = {

};

export default IndexPage;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  padding-top: 4em;


  img {
    width: 100%;
    height: auto;
    &.golfers-desktop {
      display: none;
    }
  }
  @media all and (min-width: 700px) {
    img.golfers {
      display: none;
    }
    img.golfers-desktop {
      display: block;
    }
  }
`;

const CtaContainer = styled.section`
  margin: 4em 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  h1,h2 {
    text-align: center;
    color: ${({ theme }) => theme.colors.Primary};
  }
  h2 {
    font-size: 2em;
    margin: 1em 0;
    font-family: 'Averia Serif Libre', serif;
  }
`;

const StyledLink = styled(Link)`
  text-transform: uppercase;
  font-family: 'Bebas Neue', serif;
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 2.4em;
  border: 2px solid ${({ theme }) => theme.colors.Primary};
  padding: 0.25em 1em;
`;
